import { type Toast, ToastType } from "~/components/platform/globals/Toast/Toast";
import { ToastPosition } from "~/components/platform/globals/Toast/Toast";

const state = ref({
	toasts: [] as Partial<Toast>[],
	options: {
		position: ToastPosition.BottomCenter,
		emptyToastOnRouteChange: true,
	},
});

/**
 * Composable function to manage toast notifications.
 *
 * @param {Object} [options] - Optional settings for the toast notifications.
 * @param {ToastPosition} [options.toastPosition] - Optional initial position for the toasts.
 * @param {boolean} [options.emptyToastOnRoute] - Optional whether to empty toasts on route change.
 * @returns {Object} - Methods and properties to manage toasts.
 */
export default function useToast(options?: { toastPosition?: ToastPosition; emptyToastOnRoute?: boolean }): {
	add: (toast: Partial<Toast>) => void;
	remove: (id: number) => void;
	emptyToasts: () => void;
	toasts: ComputedRef<Partial<Toast>[]>;
	position: ComputedRef<ToastPosition>;
	emptyToastOnRouteChange: ComputedRef<boolean>;
} {
	if (options?.toastPosition) {
		state.value.options.position = options.toastPosition;
	}
	if (options?.emptyToastOnRoute) {
		state.value.options.emptyToastOnRouteChange = options.emptyToastOnRoute;
	}

	/**
	 * Computed property to get the list of toasts.
	 *
	 * @returns {ComputedRef<Partial<Toast>[]>} - List of toasts.
	 */
	const toasts = computed(() => state.value.toasts);

	/**
	 * Computed property to get the current position of the toasts.
	 *
	 * @returns {ComputedRef<ToastPosition>} - Current position of the toasts.
	 */
	const position = computed(() => state.value.options.position);

	/**
	 * Computed property to get the emptyToastOnRouteChange option.
	 *
	 * @returns {ComputedRef<boolean>} - Whether to empty toasts on route change.
	 */
	const emptyToastOnRouteChange = computed(() => state.value.options.emptyToastOnRouteChange);

	/**
	 * Adds a new toast to the list.
	 *
	 * @param {Partial<Toast>} toast - The toast object to add.
	 *
	 * @example
	 *
	 * <TnButton
	 * 			@click="
	 * 				add({
	 * 					title: 'test',
	 * 					message: 'test',
	 * 					duration: 0,
	 * 					actionButtons: [
	 * 						{
	 * 							type: 'TnButton',
	 * 							action: () => console.log('test'),
	 * 							props: {
	 * 								text: 'test this',
	 * 								secondary: true,
	 * 							},
	 * 						},
	 * 						{
	 * 							type: 'TnButton',
	 * 							action: () => console.log('test'),
	 * 							props: {
	 * 								text: 'test this',
	 * 							},
	 * 						},
	 * 					],
	 * 				})
	 * 			"
	 * 			>
	 * 			test
	 * 			</TnButton>
	 */
	const add = (toast: Partial<Toast>) => {
		state.value.toasts.push({
			id: Math.floor(Math.random() * 1000000 + 1),
			duration: 5000,
			type: ToastType.Informational,
			closable: false,
			...toast,
		});
	};

	/**
	 * Removes a toast from the list by its ID.
	 *
	 * @param {number} id - The ID of the toast to remove.
	 */
	const remove = (id: number) => {
		state.value.toasts = state.value.toasts.filter((toast) => toast.id !== id);
	};

	/**
	 * Empties the list of toasts.
	 */
	const emptyToasts = () => {
		state.value.toasts = [];
	};

	return {
		add,
		remove,
		emptyToasts,
		toasts,
		position,
		emptyToastOnRouteChange,
	};
}
