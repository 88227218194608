export interface Toast {
	id: number;
	title: string;
	message?: string;
	duration?: number;
	type: ToastType;
	closable?: boolean;
	actionButtons?: ToastActionButton[];
}

export interface ToastActionButton {
	props: TnButtonProps;
	action: () => any;
}

export interface TnButtonProps {
	text: string;
}

export enum ToastType {
	Informational = "informational",
	Neutral = "neutral",
	Success = "success",
	Warning = "warning",
	Error = "error",
	Attention = "attention",
}

export enum ToastPosition {
	TopRight = "top-right",
	TopLeft = "top-left",
	BottomRight = "bottom-right",
	BottomLeft = "bottom-left",
	TopCenter = "top-center",
	BottomCenter = "bottom-center",
}
